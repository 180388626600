import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import useStaticModule from '@hooks/useStaticModule'
import Copy from '@objects/copy'
import Container from '@objects/container'
import Stage from '@components/stage'
import ContactForm from '@components/forms/contactform'

const useStyles = makeStyles((theme) => ({
  article: {
    ...theme.mixins.article,
    '& > h2': {
      fontSize: '1.5em',
    },
  },
  headline: {
    marginBottom: theme.spacing(17),
  },
  copy: {
    marginBottom: theme.spacing(12),
  },
}))

function Kontakt({ pageContext }) {
  const classes = useStyles()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const copy = getStaticFieldValue('contact.copy', 'contact.copy.text')

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page.title}
        ariaLabel={pageContext.page.title}
      />

      <Container width="sm" ariaLabel="arialabel.kontakt">
        {!!copy && (
          <Copy
            className={classes.copy}
            type="article"
            html={copy}
            component="div"
          />
        )}
        {/* <ContactForm /> */}
      </Container>
    </>
  )
}

Kontakt.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default Kontakt
