module.exports = {
  navigation: [
    {
      messageId: 'navigation.inform',
      activeSlugId: 'inform.path',
      depth: 3,
      baseNextlevel: [
        {
          slugId: 'inform.path',
          messageId: 'navigation.inform.overview',
        },
      ],
      bottomNextlevel: [
        { slugId: 'faq.path', messageId: 'footer.faq', divider: true },
        {
          slugId: 'glossary.path',
          messageId: 'navigation.glossary',
        },
      ],
    },
    {
      messageId: 'navigation.magazine',
      depth: 2,
      nextlevel: [
        { slugId: 'magazine.path', messageId: 'navigation.magazine.overview' },
        {
          slugId: 'magazine.path.experts',
          messageId: 'navigation.magazine.experts',
        },
        {
          slugId: 'magazine.path.applications',
          messageId: 'navigation.magazine.applications',
        },
        {
          slugId: 'magazine.path.society',
          messageId: 'navigation.magazine.society',
        },
      ],
    },
    {
      messageId: 'navigation.dialog',
      depth: 2,
      nextlevel: [
        {
          slugId: 'dialog.events.path',
          messageId: 'navigation.dialog.events',
        },
        { slugId: 'contact.path', messageId: 'footer.contact' },
      ],
    },
    { slugId: 'about.path', messageId: 'navigation.about' },
    {
      messageId: 'navigation.media',
      depth: 2,
      nextlevel: [
        {
          slugId: 'media.path',
          messageId: 'navigation.media.overview',
        },
        {
          slugId: 'mediathek.path',
          messageId: 'navigation.mediathek',
        },
        {
          slugId: 'printmaterial.path',
          messageId: 'navigation.printmaterial',
        },
      ],
    },
  ],
  footer: [
    {
      headingId: 'footer.initiative',
      items: [
        { slugId: 'about.path', messageId: 'footer.about' },
        { slugId: 'contact.path', messageId: 'footer.contact' },
      ],
    },
    {
      headingId: 'footer.populartopics',
      items: [
        { slugId: 'footer.inform.path', messageId: 'footer.inform' },
        { slugId: 'footer.faq.path', messageId: 'footer.faq' },
        { slugId: 'footer.glossary.path', messageId: 'footer.glossary' },
      ],
    },
    {
      headingId: 'footer.legal',
      items: [
        { slugId: 'imprint.path', messageId: 'footer.imprint' },
        { slugId: 'dataprivacy.path', messageId: 'footer.dataprivacy' },
        { slugId: 'accessiblity.path', messageId: 'footer.accessiblity' },
      ],
    },
  ],
}
