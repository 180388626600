import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { useIntl } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import Container from '@objects/container'
import MagazineTeaserGrid from '@components/magazineTeaserGrid'
import NewsletterTeaser from '@components/newsletterTeaser'

import { makeStyles } from '@material-ui/core/styles'
import FilteredMagazineTeaserList from '@components/filteredMagazineTeaserList'

const useStyles = makeStyles((theme) => ({
  root: {},
  magazineTeaserHeadline: {
    textAlign: 'left',
  },
}))

function RootIndex({ data, pageContext, modules }) {
  const intl = useIntl()
  const classes = useStyles()
  let magazineCategories = new Set()

  const { differenceInDays } = useDateFns()

  const dataMagazineArticles = get(data, 'magazin')
  let magazineArticles = []
  if (!!dataMagazineArticles) {
    dataMagazineArticles.categories.forEach((cat, index) => {
      cat.nextLevel?.forEach((article, index) => {
        if (!!article.title) {
          if (
            article.magazineCategory?.length > 0 &&
            !magazineCategories.has(article.magazineCategory)
          ) {
            magazineCategories.add(article.magazineCategory)
          }
          magazineArticles.push({
            createdAt: article.date ?? article.createdAt,
            readingTime: article.readingTime,
            subline: cat.title,
            headline: article.title,
            copy: article.teaserCopy?.childMarkdownRemark?.html,
            image: article.teaserImageWithFocalpoint
              ? article.teaserImageWithFocalpoint.image.fluid
              : article.teaserImage?.fluid,
            imageAlt: article.teaserImageWithFocalpoint
              ? article.teaserImageWithFocalpoint.image.description
              : article.teaserImage?.description,
            focalPoint: article.teaserImageWithFocalpoint?.focalPoint,
            slug: !!article.slug
              ? `${intl.formatMessage({ id: 'magazine.path' })}${article.slug}/`
              : article.url,
            magazineCategory: article.magazineCategory,
            url: article.url,
          })
        }
      })
    })

    magazineArticles = magazineArticles.sort((a, b) =>
      differenceInDays(b.createdAt, a.createdAt)
    )
  }

  const dataHomeMagazineArticles = get(data, 'homeMagazin')

  let homeMagazineArticles = []
  if (!!dataHomeMagazineArticles) {
    dataHomeMagazineArticles.categories.forEach((cat, index) => {
      const firstArticle = cat.nextLevel[0]
      homeMagazineArticles.push({
        createdAt: firstArticle.date ?? firstArticle.createdAt,
        readingTime: firstArticle.readingTime,
        subline: cat.title,
        headline: firstArticle.title,
        copy: firstArticle.teaserCopy?.childMarkdownRemark?.html,
        image: firstArticle.teaserImageWithFocalpoint
          ? firstArticle.teaserImageWithFocalpoint.image.fluid
          : firstArticle.teaserImage?.fluid,
        imageAlt: firstArticle.teaserImageWithFocalpoint
          ? firstArticle.teaserImageWithFocalpoint.image.description
          : firstArticle.teaserImage?.description,
        focalPoint: firstArticle.teaserImageWithFocalpoint?.focalPoint,
        slug: !!firstArticle.slug
          ? `${intl.formatMessage({ id: 'magazine.path' })}${
              firstArticle.slug
            }/`
          : firstArticle.url,
        url: firstArticle.url,
      })
    })
  }

  return (
    <>
      <Container type={'top'} ariaLabel="arialabel.magazineteasergrid">
        <MagazineTeaserGrid
          customClasses={{ headline: classes.magazineTeaserHeadline }}
          headline={intl.formatMessage({ id: 'magazine.title.newArticles' })}
          articles={[magazineArticles[0], ...homeMagazineArticles]}
          layout="heros"
        />
      </Container>

      <Container ariaLabel="arialabel.magazineteaserlist">
        <FilteredMagazineTeaserList
          articles={magazineArticles}
          categories={[...magazineCategories]}
        />
      </Container>

      {/* <NewsletterTeaser
        title={intl.formatMessage({ id: 'newsletter.hl' })}
        copy={intl.formatMessage({ id: 'newsletter.copy' })}
      /> */}
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default RootIndex

export const pageQuery = graphql`
  query MagazinePageQuery($locale: String!) {
    homeMagazin: contentfulTopicCategoryList(
      identifier: { eq: "homemagazin" }
      node_locale: { eq: $locale }
    ) {
      title
      categories {
        title
        nextLevel {
          ... on ContentfulArticle {
            __typename
            createdAt
            date
            title
            slug
            teaserImage {
              fluid(maxWidth: 304, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            teaserImageWithFocalpoint {
              image {
                fluid(maxWidth: 704, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              focalPoint
            }
            teaserCopy {
              childMarkdownRemark {
                html
              }
            }
            readingTime
          }
          ... on ContentfulPageExternal {
            __typename
            createdAt
            title
            teaserImage {
              fluid(maxWidth: 304, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            teaserImageWithFocalpoint {
              image {
                fluid(maxWidth: 704, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              focalPoint
            }
            teaserCopy {
              childMarkdownRemark {
                html
              }
            }
            url
          }
        }
      }
    }
    magazin: contentfulTopicCategoryList(
      identifier: { eq: "magazin" }
      node_locale: { eq: $locale }
    ) {
      title
      categories {
        title
        nextLevel {
          ... on ContentfulArticle {
            __typename
            createdAt
            date
            title
            slug
            teaserImage {
              fluid(maxWidth: 304, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            teaserImageWithFocalpoint {
              image {
                fluid(maxWidth: 704, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              focalPoint
            }
            teaserCopy {
              childMarkdownRemark {
                html
              }
            }
            readingTime
            magazineCategory
          }
          ... on ContentfulPageExternal {
            __typename
            createdAt
            title
            teaserImage {
              fluid(maxWidth: 304, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            teaserImageWithFocalpoint {
              image {
                fluid(maxWidth: 704, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              focalPoint
            }
            teaserCopy {
              childMarkdownRemark {
                html
              }
            }
            url
          }
        }
      }
    }
  }
`
